<template>
  <div class="p_relative w100" style="overflow: hidden;height: 47vh;background-color: #fff;margin-bottom: 1vw">
    <div class="graphName p_relative an_fadeInOut">
      {{ $attrs.name }}
      <div
        class="p_absolute graph_item "
        :style="`left: ${(360 / $attrs.list.length) * x == 90 ? '85%' : (360 / $attrs.list.length) * x == 270 ? '15%' : '50%'};transform: translateX(-50%) rotate(${
          (360 / $attrs.list.length) * x
        }deg) translateY(-4vh)`"
        v-for="(i, x) in $attrs.list"
        :key="x"
      >
        <div @click="itemClick(i)" :class="`ellipsis article_btn ${ graphItemKey == i.id && 'active' }` " :style="`transform: translateY(-1vh) rotate(-${(360 / $attrs.list.length) * x}deg);
`">
          {{ i.catalogName }}
        </div>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>


<script >
import { reactive, toRefs, onMounted, watch } from "vue";

export default {
  name: "KnowGraph",
  props: {
    itemClick: {
      default: () => {}
    },
    offsetObj: {
      '90': '85%',
      '270': '15%'
    }
    // list: {
    //   default: []
    // }
  },
  setup(props) {
    const state = reactive({
    graphItemKey: '',
      mockData: {
        name: "实践技能",
        children: [],
      },
    });

    watch(() => props.itemClick, (newList) => {
      console.log("newList", newList);
    })

    onMounted(() => {
        console.log("props.graphList", props);
    });

    const itemClick = (i) => {
      state.graphItemKey = i.id;
      props.itemClick && props.itemClick(i);
    }

    return {
      ...toRefs(state),
      itemClick
    };
  },
};
</script>

<style scoped lang="less">
.line {
}

.active {
    background-color: #fca307 !important;
    color: #fff !important;
    z-index: 999;
}

.graphName {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fca307;
  font-weight: bold;

  .graph_item {
    width: 1px;
    bottom: 50%;
    left: 50%;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform-origin: bottom center;

    .article_btn {
      max-width: 10em;
        padding: 0.5vw 1.5vw;
        background-color: #F2F2F2;
        border-radius: 20px;
        color: #666;
        font-size: .8em;
        transition: all .3s;

        &:hover {
            cursor: pointer;
        }
    }

    .line {
      width: 2px;
      height: 11vh;
      background-color: #D7D7D7;
    }
  }
}
</style>

<style >
.an_fadeInOut {
  animation-name: fadeInOut;
  animation-duration: .6s;
}

@keyframes fadeInOut {
  from {
    opacity: 0;
    left: 0%;
    transform: scale(.6);
  }
  to {
    opacity: 1;
    left: 50%;
  }
  
}
</style>