
let show 

export function init(_show){
    show = _show;
}

export function login(callback){
    // 弹出
    show && show(callback);
}
