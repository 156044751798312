<template>
    <div class="header p_sticky" style="position: fixed">
      <!-- <KnowledgeGraph /> -->
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="logo" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li v-for="(item, index) in links" :key="index" :class="{ active: item.active }">
              <router-link :to="item.url || '/'" :class="{ active: item.url === '/' }">{{ item.name }}</router-link>
            </li>
          </ul>
          <Avatar style="margin-right: 37px;" />
        </div>
      </div>
    </div>
    <div class="swiper-container homebarswiper">
      <div class="swiper-wrapper">
        <div class="bar-swiper swiper-slide" v-for="(item, index) in bars" :key="index">
          <router-link :to="item.linkAddress || '/'" class="swiper-link">
            <img class="swiper-img" :src="item.imgUrl" />
          </router-link>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="wrap mt2">
      <div class="part-header" style="margin: 10px">
        <div class="flex-row align-end justify-between">
          <h2>课程精选</h2>
          <router-link to="/courses" class="more">查看更多</router-link>
        </div>
        <div class="line mt05">
          <div class="line-inner" style="width: 3em"></div>
        </div>
      </div>
      <div class="mt2 flex-row flex-wrap">
        <router-link :to="'/course/' + item.id" v-for="(item, index) in courses" :key="index" class="video-item">
          <img :src="item.cover" />
          <div class="info ellipsis" v-text="item.courseName"></div>
        </router-link>
      </div>
    </div>
    <div class="wrap mt2">
      <div class="part-header" style="margin: 10px">
        <div class="flex-row align-end justify-between">
          <h2>平台动态</h2>
          <router-link to="/trend" class="more">查看更多</router-link>
        </div>
        <div class="line mt05">
          <div class="line-inner" style="width: 3em"></div>
        </div>
      </div>
      <div class="mt2 flex-row flex-wrap justify-between">
        <router-link :to="'/article/' + item.id" v-for="(item, index) in articles" :key="index" class="article-item">
          <div class="flex-row">
            <div class="text-center">
              <div class="article-year" v-text="item.year"></div>
              <div class="article-day" v-text="item.day"></div>
            </div>
            <div class="article-title flex1">
              <div class="ellipsis2" v-text="item.title"></div>
            </div>
          </div>
          <div class="article-intro ellipsis3" v-text="item.intro"></div>
        </router-link>
      </div>
    </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import Swiper from "swiper";
import { useRoute, useRouter } from "vue-router";
import "swiper/css/swiper.min.css";
import { get, getLogindata, post } from "../utils/common";
import moment from "moment";
import { login } from "@/components/Login";

const logo = require("../assets/logo.jpg");

const route = useRoute();
const router = useRouter();
@Options({
  components: {},
})
export default class Home extends Vue {
  bars = [];

  swiper;

  logo = logo;

  courses = [];

  articles = [];
  loginModal = false;

  mounted() {
    this.load();

  }

  beforeUnmount() {
    if (this.swiper) {
      this.swiper.destroy();
    }
  }

  log() {
    // this.loginModal=true;
    console.log('try to login');
    if (getLogindata()) {
      // 具体业务
    } else {
      login(() => {
        alert('登录成功')
        // 具体业务
      })
    }
  }

  load() {
    get(`web_home/getHomeCarousel`, (data) => {
      this.bars = (data && data.data) || [];
      if (this.bars.length) {
        setTimeout(() => {
          this.swiper = new Swiper(".swiper-container.homebarswiper", {
            autoplay: {},
            pagination: {
              el: ".swiper-pagination",
            },
          });
        }, 100);
      }
    });
    // web_platform/listPlatformCourse  课程列表
    post(
      `web_platform/listPlatformCourse`,
      { page: { from: 0, size: 6 }, params: { sortType: 1 } },
      (data) => {
        this.courses =
          (data && data.data && data.data.list).map((item) => {
            item.cover = item.imgUrl ? JSON.parse(item.imgUrl)[0] : "";
            return item;
          }) || [];
      }
    );
    // web_platform/getArticleDetail  文章详情
    post(
      `web_platform/listPlatformArticle`,
      { page: { from: 0, size: 4 }, params: {} },
      (data, res) => {
        console.log(data && data.data);
        this.articles =
          (data &&
            data.data.map((item) => {
              const date = item.webPublishTime
                ? moment(item.webPublishTime)
                : null;
              return {
                id: item.id,
                title: item.articleName,
                intro: item.briefContent,
                year: date && date.format("YYYY"),
                day: date && date.format("MM.DD"),
              };
            })) ||
          [];
      }
    );
  }
}
</script>

<style scoped lang="less">
.part-header {
  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  .line {
    height: 2px;
    background: #ddd;
    font-size: 1.6rem;
    font-weight: bold;
    margin: 1rem 0.5em 0;

    .line-inner {
      background: #f39801;
      height: 100%;
    }
  }
}

.header {
  position: absolute;
  width: 100%;
  min-width: 1200px;
  top: 0;
  left: 0;
  z-index: 10;
  box-shadow: none;
  background-color: transparent;
}

.homebarswiper {
  height: 400px;
}

.bar-swiper {
  height: 400px;
}

.swiper-link {
  width: 100%;
  height: 100%;
  display: block;

  .swiper-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.video-item {
  margin: 10px;
  width: 380px;
  height: 214px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    line-height: 2;
    padding: 0 0.5em;
    text-align: center;
  }
}

.article-item {
  margin: 10px;
  width: 560px;
  height: 12rem;

  .article-year {
    color: #a0a0a0;
  }

  .article-day {
    color: #a0a0a0;
    font-size: 2rem;
    width: 3.5em;
  }

  .article-title {
    font-size: 1.3rem;
    font-weight: bold;
  }

  .article-intro {
    color: #a0a0a0;
    margin-top: 0.3rem;
  }
}

button {
  height: 1.8rem;
  width: 10rem;
  background: #FF9552;
  color: #fff;
  margin-left: .5rem;
  border-radius: 4px;
}
</style>