// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1635147194035");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1635147194035");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1635147194035");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face{font-family:picon;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.picon{font-family:picon!important;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.pi-view:before{content:\"\\e6b4\"}.pi-user:before{content:\"\\e60a\"}.pi-android:before{content:\"\\e9cb\"}.pi-apple-fill:before{content:\"\\e881\"}.pi-boshimao:before{content:\"\\e601\"}.pi-yiyuan:before{content:\"\\e636\"}.pi-zhishiku:before{content:\"\\e621\"}.pi-shequ:before{content:\"\\e69e\"}", ""]);
// Exports
module.exports = exports;
