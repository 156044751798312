<template>
  <div class="g-root flex-row">
    <div v-if="loading" class="loading-mask">
      <div class="loading">
        <img :src="require('../assets/loading.gif')" >
      </div>
    </div>
    <div ref="container" class="flex1 zstk-container">
      <div class="label">
        <div class="panel">中药调剂知识库</div>
      </div>
    </div>
    <div class="operate">
      <div class="btns">
        <div @click="graph_reset" class="graph-operate">
          <img :src="require('../pages/app/images/reduction.png')" />
          <div class="tool-tip">还原</div>
        </div>
        <div @click="graph_zoomout" class="graph-operate">
          <img :src="require('../pages/app/images/fangda.png')" />
          <div class="tool-tip">放大</div>
        </div>
        <div @click="graph_zoomin" class="graph-operate">
          <img :src="require('../pages/app/images/suoxiao.png')" />
          <div class="tool-tip">缩小</div>
        </div>
        <div @click="graph_zoom1" class="graph-operate">
          <img :src="require('../pages/app/images/chicun.png')" />
          <div class="tool-tip">原尺寸</div>
        </div>
      </div>
      <div
        :class="'t-right' + (showArticles ? ' expanded' : '')"
        @click="switchShow(!showArticles)"
      ></div>
    </div>
    <div class="articles" :style="showArticles ? '' : 'width: 0;'">
      <div
        class="articles-content h100 flex-column"
        style="border: 1px solid #ccc; font-size: 14px"
      >
        <div style="padding: 0.78vw; border-bottom: 1px solid #ff9552">
          <div
            class="head bold"
            style="font-size: 1.4em; color: #ff9552; margin-bottom: 0.89vw"
          >
            相关内容
          </div>
          <div class="flex-row bold" style="color: #999999">
            <div>共{{ page.data.length }}条</div>
            <div class="flex1 text-right">
              <span
                class="t-left button"
                style="padding: 0.4em 0.8em"
                @click="showpage(page.num - 1)"
              ></span>
              <span style="margin: 0 1em">
                {{ page.num }}/{{ page.total }}
              </span>
              <span
                class="t-right button"
                style="padding: 0.4em 0.8em"
                @click="showpage(page.num + 1)"
              ></span>
            </div>
          </div>
        </div>
        <div id="computedH" class="flex1 flex-column">
          <div
            v-for="(item, index) in page.articles"
            :key="index"
            :class="`${page.articles.length == pageSize ? 'flex1' : 'flex1'} article flex-column`"
            style="
              max-height: 147px;
              margin: 0 0.78vw;
              border-bottom: 1px solid #ccc;
              overflow: hidden;
            "
          >
            <div
              class="bold "
              style="
                border-left: 0.4em solid #ff9552;
                color: #333;
                padding-left: 0.6em;
                margin-top: 0.78vw;
              "
            >
              <a
                :href="`#/know_detail?id=${item.id}&knowledgeBaseId=${item.knowledgeBaseId}`"
                target="_blank"
                class="article-tt ellipsis2"
                style="display: -webkit-box;"
                v-text="item.catalogName"
              ></a>
            </div>
            <div
              class=" ellipsis2"
              style="color: #999999; margin: 0.1vw 0 0em 1.15vw"
              v-text="item.catalogIntro"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import G6 from "@antv/g6";
import { get, post } from "@/utils/common";
const logo = require("../assets/logo.jpg");

const colors = ["#EE752F", "#EE752F", "#F19336", "#F7C856"];

const node_focus = {
  style: {
    stroke: "#BEF264",
    shadowColor: "#BEF264",
    shadowBlur: 10,
    lineWidth: 1,
  },
};

const node_light = {
  style: {
    stroke: "#EE752F",
    shadowColor: "#EE752F",
    shadowBlur: 10,
    lineWidth: 1,
  },
};

const line_normal = {
  style: {
    stroke: "#bbbbbb",
    shadowBlur: 0,
    lineWidth: 1,
  },
};

const line_light = {
  style: {
    stroke: "#EE752F",
    shadowColor: "#EE752F",
    shadowBlur: 3,
    lineWidth: 1,
  },
};

const line_parent_light = {
  style: {
    stroke: "#1EAD39",
    shadowColor: "#1EAD39",
    shadowBlur: 3,
    lineWidth: 2,
  },
};

@Options({
  components: {},
})
export default class Home extends Vue {
  logo = logo;
  showArticles = false;
  articles = [];
  datamap = {};
  loading = false;
  pageSize = 6;
  mounted() {
    this.$watch("$attrs.resizetime", (v1, v2) => {
      this.resize();
    });

    function buildNodeStyle(item, hover) {
      if (item.id === "0") {
        return {
          style: {
            fill: "l(90) 0:#F4511E  1:#F6C7A1",
            stroke: "#EE752F",
            shadowColor: "#EE752F",
            shadowBlur: 0,
            ...(item.focused ? node_focus.style : {}),
            ...(hover ? node_light.style : {}),
          },
          labelCfg: {
            style: { fill: "white", fontWeight: 900 },
          },
        };
      } else {
        return {
          style:
            item.children && item.children.length
              ? {
                  fill: colors[item.level] || "#EED868",
                  stroke: colors[item.level] || "#EED868",
                  radius: 6,
                  lineWidth: 0,
                  shadowBlur: 0,
                  ...(item.focused ? node_focus.style : {}),
                  ...(hover ? node_light.style : {}),
                }
              : {
                  fill: "white",
                  stroke: "#F19336",
                  radius: 6,
                  lineWidth: 1,
                  shadowBlur: 0,
                  ...(item.focused ? node_focus.style : {}),
                  ...(hover ? node_light.style : {}),
                },
          labelCfg:
            item.children && item.children.length
              ? {
                  style: {
                    fill: "white",
                    fontWeight: 900,
                  },
                }
              : {
                  style: {
                    fill: "#F19336",
                    fontWeight: 900,
                  },
                },
        };
      }
    }

    this.buildNodeStyle = buildNodeStyle;
    
    this.loading = true;
    post(
      "WebKnowledgeBase/getALLCatalog",
      {},
      (res) => {
        this.loading = false;
        const map = (this.datamap = {});
        function tomap(nodes, node) {
          map[node.id] = node;
          node.children = !nodes
            ? null
            : nodes.map((item) => {
                const tmp = {
                  ...item,
                  id: item.id + "",
                  label: item.catalogAbbr,
                  parentId: item.parentId + "",
                  level: node.level + 1,
                };
                tomap(item.children, tmp);
                return tmp;
              });
        }

        const root = {
          // fx: 0,
          // fy: 0,
          id: "0",
          label: "知识库图谱",
          level: 0,
          type: "circle",
          style: {
            fill: "l(90) 0:#F4511E  1:#F6C7A1",
            stroke: "#EE752F",
          },
          labelCfg: {
            style: { fill: "white", fontWeight: 900 },
          },
        };
        tomap(res.data, root);

        const container = this.$refs.container;
        const width = container && container.scrollWidth;
        const height = container && container.scrollHeight || 500;
        const graph = (this.graph = new G6.Graph({
          container,
          width,
          height,
          layout: {
            type: "force",
            preventOverlap: true,
            nodeSpacing: 20,
            //   gravity: 0.001,
            // edgeStrength: 0.4,
            nodeStrength: -200,
            alpha: 0.2,
            // alphaMin: 0.001,
            alphaDecay: 0.07,
            collideStrength: 0.5,
          },
          defaultNode: {
            type: "rect",
            size: [110, 40],
            anchorPoints: [[0.5, 0.5]],
          },
          defaultEdge: {
            style: {
              stroke: "#bbbbbb",
            },
          },
          fitView: false,
          fitCenter: false,
          modes: {
            default: [
              "drag-canvas",
              // "zoom-canvas"
            ],
          },
        }));

        graph.on("node:click", (e) => {
          console.log("node click");
          const item = e.item;
          const node = map[item.getID()];

          if (this.node_focused_id) {
            let tmp = graph.findById(this.node_focused_id);
            if (tmp) {
              map[this.node_focused_id].focused = false;
              tmp.update(
                Object.assign(tmp.getModel(), {
                  style: this.buildNodeStyle(map[this.node_focused_id]).style,
                })
              );
              tmp.refresh();
            }
          }

          this.node_focused_id = node.id;
          node.focused = true;

          item.update(
            Object.assign(item.getModel(), {
              style: this.buildNodeStyle(node).style,
            })
          );

          const articles = [];
          function addarticle(nodes) {
            nodes.forEach((item) => {
              if (!item.children || !item.children.length) {
                articles.push(item);
              }
              if (item.children) {
                addarticle(item.children);
              }
            });
          }
          addarticle([node]);

          this.loadArticles(articles);

          this.expandNode(item);
        });

        graph.data({
          nodes: [root],
          edges: [],
        });
        graph.render();

        let mouseovernode;

        graph.on("node:mouseenter", (e) => {
          if (mouseovernode) {
          }
          mouseovernode = e.item;

          const item = mouseovernode.getModel();
          mouseovernode.update(
            Object.assign(item, {
              style: {
                ...this.buildNodeStyle(map[item.id], true).style,
              },
            })
          );
          mouseovernode.getInEdges().forEach((edge) => {
            edge.update(line_parent_light);
          });
          mouseovernode.getOutEdges().forEach((edge) => {
            edge.update(line_light);
          });
        });

        graph.on("node:mouseleave", (e) => {
          if (mouseovernode) {
            const item = mouseovernode.getModel();
            mouseovernode.update(
              Object.assign(item, {
                style: {
                  ...this.buildNodeStyle(map[item.id], false).style,
                },
              })
            );
            mouseovernode.getInEdges().forEach((edge) => {
              edge.update(line_normal);
            });
            mouseovernode.getOutEdges().forEach((edge) => {
              edge.update(line_normal);
            });
            mouseovernode = null;
          }
        });

        this.expandNode(graph.findById("0"));
      },
      () => {
        this.loading = false;
      }
    );
    window.addEventListener("resize", this.resize);
  }

  unmounted() {
    window.removeEventListener("resize", this.resize);
  }

  switchShow(showArticles) {
    this.showArticles = showArticles;
    setTimeout(this.resize, 300);
  }

  resize() {
    const graph = this.graph;
    const container = this.$refs.container;
    if (!graph || graph.get("destroyed")) {
      return;
    }
    if (!container || !container.clientWidth || !container.clientHeight) {
      return;
    }
    graph.changeSize(container.clientWidth, container.clientHeight);
    const item = graph.findById(this.node_focused_id || "0");
    item &&
      graph.focusItem(item, true, {
        duration: 80,
      });
  }

  shrink(item) {
    const map = this.datamap;
    const edges = [...item.getInEdges()];
    const nodes = [];
    function collect(item) {
      map[item.getID()].expand = false;
      nodes.push(item);
      item.getOutEdges().forEach((edge) => {
        edges.push(edge);
        collect(edge.getTarget());
      });
    }
    collect(item);
    const graph = this.graph;
    edges.forEach((item) => graph.removeItem(item));
    nodes.forEach((item) => graph.removeItem(item));
  }

  expandNode(item) {
    const el = item;
    const map = this.datamap;
    const node = map[item.getID()];
    const graph = this.graph;
    if (node && node.children && node.children.length > 0) {
      if (node.expand) {
        node.expand = false;
        item.getOutEdges().forEach((edge) => this.shrink(edge.getTarget()));
        graph.layout();
      } else {
        const id = node.id;
        const parent = item.getInEdges()[0] && item.getInEdges()[0].getSource();

        let deg = 0;
        if (parent) {
          let start = item.getModel();
          let end = parent.getModel();
          const r = Math.sqrt(
            (start.x - end.x) * (start.x - end.x) +
              (start.y - end.y) * (start.y - end.y)
          );
          deg =
            r === 0
              ? 0
              : start.y >= end.y
              ? Math.acos((end.x - start.x) / r)
              : Math.PI * 2 - Math.acos((end.x - start.x) / r);
          const pp =
            parent.getInEdges()[0] && parent.getInEdges()[0].getSource();
          if (pp) {
            let start = parent.getModel();
            let end = pp.getModel();
            const r = Math.sqrt(
              (start.x - end.x) * (start.x - end.x) +
                (start.y - end.y) * (start.y - end.y)
            );
            const deg2 =
              r === 0
                ? 0
                : start.y >= end.y
                ? Math.acos((end.x - start.x) / r)
                : Math.PI * 2 - Math.acos((end.x - start.x) / r);
            const deg3 = deg > Math.PI ? deg - Math.PI : deg + Math.PI;
            if (Math.abs(deg2 - deg3) < (Math.PI * 1) / 2) {
              console.log("parent to click node deg:", (deg3 / Math.PI) * 180);
              console.log(
                "parent to parents parent deg",
                (deg2 / Math.PI) * 180
              );
              deg = deg2 + Math.PI;
              console.log("node new deg:", (deg / Math.PI) * 180);
              // item.getModel().x = start.x + 120 * Math.cos(deg);
              // item.getModel().y = start.y - 120 * Math.sin(deg);
              // item.draw();
              const children = parent.getModel().children;
              const index = children.findIndex((e) => e.id === id);
              parent.getOutEdges().forEach((el, i) => {
                const child = el.getTarget();
                const tmp =
                  deg +
                  (Math.PI *
                    2 *
                    (index > i ? i + children.length - index : i - index)) /
                    children.length;
                child.getModel().x = start.x + 80 * Math.cos(tmp);
                child.getModel().y = start.y - 80 * Math.sin(tmp);
              });

              deg -= Math.PI;
            }
          }
          item.getModel().x -= 80 * Math.cos(deg);
          item.getModel().y += 80 * Math.sin(deg);
        }

        node.expand = true;
        const nodes = node.children;
        // deg += Math.PI;
        nodes.forEach((item, index) => {
          let tmp = deg + (Math.PI * 2 * (index + 1)) / (nodes.length + 1);
          graph.addItem(
            "node",
            {
              ...item,
              label:
                item.label && item.label.length > 8
                  ? item.label.substring(0, 7) + ".."
                  : item.label,
              x: el.getModel().x + Math.cos(tmp) * 4,
              y: el.getModel().y - Math.sin(tmp) * 4,
              ...this.buildNodeStyle(item),
            },
            false
          );
          graph.addItem(
            "edge",
            {
              id: id + "_" + item.id,
              source: id,
              target: item.id,
            },
            false
          );
        });

        if (parent) {
          parent.getOutEdges().forEach((item) => {
            item = item.getTarget();
            if (item.getID() !== id) {
              map[item.getID()].expand = false;
              item
                .getOutEdges()
                .map((edge) => edge.getTarget())
                .forEach((item) => this.shrink(item));
            }
          });
        }
        graph.layout();

        setTimeout(() => {
          graph.focusItem(el, true, {
            duration: 300,
          });
        }, 200);
      }
      // graph.focusItem(e.item, true);
    }
  }

  page = {
    data: [],
    total: 0,
    num: 1,
    articles: [],
  };

  loadArticles(articles) {
    const domH = document.getElementById("computedH");
    this.pageSize = 5;
    articles = articles || [];
    this.page = {
      data: articles,
      total: Math.ceil(articles.length / this.pageSize),
      num: 1,
      articles: [],
    };
    this.switchShow(true);
    this.showpage(1);
  }
  showpage(num) {
    console.log("show page ", num);
    if (num > this.page.total || num < 1) {
      return;
    }
    const from = (num - 1) * this.pageSize;
    this.page.articles = this.page.data.slice(
      from,
      Math.min(this.page.data.length, from + this.pageSize)
    );
    this.page.num = num;
    console.log(this.page.articles);
  }

  graph_reset() {
    const { graph, datamap } = this;
    graph
      .findAll("node", (node) => datamap[node.getID()].level > 1)
      .forEach((node) => {
        graph.removeItem(node);
      });
    if (this.node_focused_id) {
      let tmp = graph.findById(this.node_focused_id);
      if (tmp) {
        datamap[this.node_focused_id].focused = false;
        tmp.update(
          Object.assign(tmp.getModel(), {
            style: this.buildNodeStyle(datamap[this.node_focused_id]).style,
          })
        );
        tmp.refresh();
      }
    }
    graph.zoomTo(1);
    graph.layout();
    setTimeout(() => {
      graph.focusItem(graph.findById("0"));
    }, 300);
    this.page = {
      data: [],
      total: 0,
      num: 1,
      articles: [],
    };
  }

  graph_zoomin() {
    this.graph.zoomTo(this.graph.getZoom() - 0.25);
    this.graph.focusItem(this.graph.findById(this.node_focused_id || "0"));
  }
  graph_zoomout() {
    this.graph.zoomTo(this.graph.getZoom() + 0.25);
    this.graph.focusItem(this.graph.findById(this.node_focused_id || "0"));
  }
  graph_zoom1() {
    this.graph.zoomTo(1);
    this.graph.focusItem(this.graph.findById(this.node_focused_id || "0"));
  }
}
</script>

<style scoped lang="less">
.operate {
  width: 0;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btns {
    font-size: 2em;
    position: absolute;
    top: 1.5em;
    right: 0.5em;
    border-radius: 3px;
    box-shadow: 2px 2px 3px #dddddd;
    background-color: white;
    .graph-operate {
      position: relative;
      padding: 0.3em;
      .tool-tip {
        background: rgba(100, 100, 100, 0.5);
        color: white;
        white-space: nowrap;
        padding: 0.3em 0.5em;
        line-height: 1;
        position: absolute;
        right: 110%;
        top: 0;
        font-size: 14px;
        display: none;
        border-radius: 2px;
      }
      img {
        display: block;
      }
      &:hover {
        .tool-tip {
          display: block;
        }
      }
    }
  }
  .t-right {
    background: white;
    display: inline-block;
    position: absolute;
    right: 0;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    box-shadow: 0.6px 1px 5px #ddd;
    font-size: 1.9em;
    color: #888888;
    transition: all 0.3s;
    transform: rotateZ(180deg);
    &.expanded {
      right: -0.5em;
      transform: rotateZ(360deg);
    }
  }
}
.articles {
  overflow: hidden;
  transition: all 0.3s;
  background-color: white;
  width: 385px;
  box-shadow: -1px 0 3px #ccc;
  .articles-content {
    width: 385px;
  }
}
.article {
  .article-tt {
    display: inline-block;
    line-height: 1.4;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-size: 1.1em;
  }
  &:hover {
    .article-tt {
      border-bottom: 2px solid #999999;
    }
  }
}

.zstk-container {
  background-image: url("../assets/bg_zstp.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  .label {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    color: #666;
    width: 100%;
    .panel {
      //box-shadow: 1px 0.5px 5px #ccc;
      //padding: 0 1em;
      line-height: 2.4;
      //margin: 1em;
      //border-radius: 3px;
      text-align: center;
      //background-color: rgba(255, 255, 255, 1);
    }
  }
  > canvas {
    position: relative;
  }
}

.g-root {
  height: 100%;
  width: 100%;
  position: relative;
  .loading-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    .loading2 {
      background-color: rgba(100, 100, 100, 0.5);
      color: white;
      padding: 1em;
      border-radius: 5px;
      .icon {
        animation: ani_spin 2.5s infinite linear;
      }
    }
  }
}

.showPadding {
  padding-bottom: 1em;
}
</style>