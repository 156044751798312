<template>
  <div class="page" v-if="$attrs.page" :style="$attrs.style">
    <div
      v-if="$attrs.page.start !== 1"
      :class="{ active: 1 === $attrs.page.current, pageitem: true }"
      @click="$attrs.topage"
      :data-page="1"
    >
      1
    </div>
    <div v-if="$attrs.page.start > 2" class="omit">•••</div>
    <div
      v-for="seq in $attrs.page.end - $attrs.page.start + 1"
      :key="seq"
      v-text="$attrs.page.start + seq - 1"
      :class="{
        active: $attrs.page.start + seq - 1 === $attrs.page.current,
        pageitem: true,
      }"
      @click="$attrs.topage"
      :data-page="$attrs.page.start + seq - 1"
    ></div>
    <div v-if="$attrs.page.end < $attrs.page.totalPage - 1" class="omit">
      •••
    </div>
    <div
      v-if="$attrs.page.end !== $attrs.page.totalPage && $attrs.page.totalPage"
      v-text="$attrs.page.totalPage"
      :class="{
        active: $attrs.page.totalPage === $attrs.page.current,
        pageitem: true,
      }"
      @click="$attrs.topage"
      :data-page="$attrs.page.totalPage"
    ></div>
  </div>
</template>

<script >
/**
 * 默认分页数据：
 * page = {
    total: 0,
    list: [],
    totalPage: 0,
    current: 1,
  } 
 */
</script>

<style scoped lang="less">
.page {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  .pageitem {
    box-sizing: border-box;
    height: 2.4em;
    min-width: 2.4em;
    padding: 0.6em;
    line-height: 1;
    border-radius: 3px;
    border: 1px solid #d8d8d8;
    text-align: center;
    margin: 0.25em;
    cursor: default;
  }
  .pageitem.active {
    border-color: #00a0ea;
    background-color: #00a0ea;
    color: white;
  }
}
</style>