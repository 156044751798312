<template>
  <div style="min-height: 100vh; background-color: #fff;">
    <router-view :key="key" />
  </div>
  <div class="footer d-flex" style="line-height: 1.6em">
    <div class="main d-flex">
      <div class="f_left flex1">
        <div class="f_title bold">
          <img class="logo" :src="require('../src/assets/logo.jpg')" />
          中医临床药学服务与调剂教育平台
        </div>
        <div class="wrap">
          主办：北京中医药大学中药调剂标准化研究中心 ｜
          <a href="http://www.tangutek.com" target="_blank"
            >技术支持：杭州唐古信息科技有限公司 </a
          ><br />
          备案证编号：2022-Present
          <a href="http://www.tangutek.com" target="_blank"
            >杭州唐古信息科技有限公司</a
          >
          All Rights Reserved |
          <a href="https://beian.miit.gov.cn" target="_blank"
            >浙ICP备09073022号-7</a
          >
          <!-- <a href="http://www.tangutek.com" target="_blank"
            >杭州唐古信息科技有限公司</a
          > -->
        </div>
      </div>

      <div class="f_right">
        <div
          class="bold"
          style="line-height: 5em; display: flex; align-items: center"
        >
          友情链接
        </div>
        <div
          class="d-flex justify-between text-center"
          style="font-size: 0.9em; color: #fff6"
        >
          <div class="qr p_relative">
            <img
              class="code_img"
              :src="require('./pages/app/images/weixin.png')"
            />
            <div>公众号</div>
            <div class="code_modal p_absolute">
              <div>
                <img
                  class="qrcode"
                  :src="require('./pages/app/images/gzh.jpg')"
                />
              </div>
              <div class="code_modal_b"></div>
            </div>
          </div>

          <div class="qr p_relative">
            <img
              class="code_img"
              :src="require('./pages/app/images/yidong.png')"
            />
            <div>移动版</div>
            <div class="code_modal p_absolute">
              <div style="padding: 14px;">
                <!-- <img class="qrcode" :src="require('./pages/app/images/appcode.png')" /> -->
                <div id="qrcode"></div>
              </div>
              <div class="code_modal_b"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="$msg.loading" class="modal-back" style="z-index: 9999">
    <div class="loading">
      <span class="icon">
        <object :data="require('./svg/loading.svg')" />
      </span>
      <span
        v-html="$msg.loading === true ? '正在加载...' : $msg.loading"
      ></span>
    </div>
  </div>
  <LoginDialog />
</template>

<script>
import LoginDialog from "./components/LoginDialog.vue";
console.log(require("./svg/loading.svg"));

export default {
  components: { LoginDialog },
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  mounted() {
    const el = document.getElementById("qrcode");
    const url = location.origin + "/app.html";
    new QRCode(el, {
      text: url,
      width: 92,
      height: 92,
    });
  },
};
</script>

<style lang="less" scoped>
.footer {
  background-color: #333333;
  color: white;

  .main {
    width: 1200px;
    margin: 0 auto;

    .f_left {
    }

    .f_right {
      width: 175px;
      height: auto;
      padding-bottom: 2em;
      box-sizing: border-box;
      margin-right: 1em;
    }
  }

  .wrap {
    width: auto;
    padding: 0em 0em 2em;
    font-size: 0.9em;
    color: #fff6;
  }
}

.logo {
  width: 1.5em;
  border-radius: 50%;
  margin-right: 0.8em;
}

.f_title {
  line-height: 5em;
  display: flex;
  align-items: center;
}

.code_img {
  width: 2em;
  border-radius: 50%;
  margin-bottom: 0.4em;
}

.qrcode {
  width: 120px;
  height: 120px;
  padding: 7.2px;
  box-sizing: border-box;
}

.qr {
  &:hover {
    .code_modal {
      display: block;
      opacity: 1;
    }
  }
}

.code_modal_b {
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 20px solid #fff;
  margin: 0 auto;
  transform: translateY(-1px);
}

.code_modal {
  display: none;
  transition: all 0.3s;
  opacity: 0;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -107%);

  & > div:first-child {
    background-color: #fff;
    box-shadow: 0 0px 3px #ccc;
    border-radius: 10px;
  }
}
</style>
