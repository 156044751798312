<template>
  <div v-show="visible" class="modal-back">
    <div
      class="wrap"
      style="display: flex; flex-direction: row; justify-content: center"
    >
      <!-- 登录框 -->
      <div
        style="
          width: 250px;
          background: #ff9952;
          height: auto;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          padding-top: 60px;
        "
      >
        <div
          class="title"
          style="
            width: 220px;
            height: 70px;
            background: #f8843d;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            box-shadow: #a04811 5px 5px 10px 1px;
          "
        >
          <img :src="logo2" class="title-logo" />
          <p class="title-text">
            中医临床药学服务<br />
            与调剂教育平台
          </p>
        </div>
      </div>
      <div class="modal">
        <!-- 关闭弹窗 -->
        <div style="margin: 6px 0 0 460px">
          <img
            src="../assets/closeModal.png"
            @click="close"
            style="width: 30px"
          />
        </div>
        <!-- 账号登录-->
        <div v-if="primary">
          <span class="modal-title">
            <p>账号登录</p>
          </span>
          <div class="tel-err" v-if="userErr">
            <img :src="telError" class="telErr" />
            手机号格式错误
          </div>
          <div class="tel-err" v-if="unRegister">
            <img :src="telError" class="telErr" />
            账号未注册，你可以<router-link
              to="./register"
              :style="`color:#FF9552`"
              ><a @click="close">去注册</a></router-link
            >
          </div>
          <div class="tel-err" v-if="orErr">
            <img :src="telError" class="telErr" />
            用户名或密码错误
          </div>
          <div class="input-info">
            <img :src="user" class="user" />
            <input
              type="text"
              id="username"
              placeholder="请输入账号"
              autocomplete="off"
              v-model="username"
              @keyup="userBlur"
              @focus="highLight"
              @blur="normal"
            />
          </div>
          <div class="input-info">
            <img :src="pwd" class="pwd" />
            <input
              type="password"
              placeholder="请输入密码"
              autocomplete="off"
              v-model="password"
              @keyup="pwdBlur"
              @focus="highLight"
              @blur="normal"
            />
          </div>
          <div style="position: relative">
            <div
              class="codeErr"
              v-if="usernameErr"
              style="position: absolute; top: -60%; left: 23%; width: 100%"
            >
              {{ userPwd }}
            </div>
            <button type="button" @click="userLogin" @mousedown="btnstyle" @mouseup="delBtnStyle" style="cursor: pointer">
              登录
            </button>
          </div>
          <span class="forget-pwd">
            <router-link to="/retrievePwd"
              ><a @click="close">忘记密码？</a></router-link
            >
          </span>
        </div>
        <!-- 手机验证码登录 -->
        <div v-if="telPage" class="telModal">
          <span class="modal-title">
            <p>手机验证码登录</p>
          </span>
          <div class="tel-err" v-if="telErr">
            <img :src="telError" class="telErr" />
            手机号格式错误
          </div>
          <div class="tel-inputTel">
            <div class="tel-tip">
              <input
                type="text"
                placeholder="+86"
                disabled
                autocomplete="off"
              />
            </div>
            <div class="tel-tel">
              <img :src="iphone" class="iphone" />
              <input
                id="iPhone"
                @keyup="onBlur"
                type="text"
                placeholder="请输入手机号"
                v-model="userTel"
                autocomplete="off"
                @focus="highLight"
                @blur="normal"
              />
            </div>
          </div>
          <!-- 滑块 -->
          <div id="drag">
            <div class="drag_bg"></div>
            <div
              class="drag_text"
              onselectstart="return false;"
              unselectable="on"
            >
              请拖动滑块，拖到最右边
            </div>
            <div class="handler handler_bg"></div>
          </div>
          <!-- 获取验证码 -->
          <div
            class="verity"
            v-if="dragStatus && !telErr && userTel.length > 10"
            style="position: relative"
          >
            <div class="verity-num">
              <input
                type="text"
                placeholder="请输入验证码"
                v-model="validCode"
                autocomplete="off"
                @focus="highLight"
                @blur="normal"
              />
            </div>
            <div>
              <button @click="getCode" :disabled="disabled" class="verity-btn">
                {{ text }}
              </button>
            </div>
            <div
              class="codeErr"
              v-if="codeErr"
              style="position: absolute; top: 106%; left: 1%; width: 100%"
            >
              {{ errMsg }}
            </div>
          </div>
          <div style="position: relative">
            <div
              class="codeErr"
              v-if="phoneErr"
              style="position: absolute; top: -60%; left: 23%; width: 100%"
            >
              请填写手机号
            </div>
            <button @click="login" @mousedown="btnstyle" @mouseup="delBtnStyle" type="button" style="cursor: pointer">
              登录
            </button>
          </div>
        </div>
        <!-- 其他登录方式 -->
        <div class="other">
          <hr class="hr" />
          <div class="option">
            <div @click="left" class="left" style="cursor: pointer">
              <img :src="tel" class="tel" />
              <span>手机</span>
            </div>
            <div class="mid"></div>
            <div @click="right" class="right" style="cursor: pointer">
              <img :src="user2" class="user2" />
              <span>账号</span>
            </div>
          </div>
          <div class="footer">
            <div class="div-checked">
              <input
                type="checkbox"
                v-model="warning"
                style="cursor: pointer"
              />
              <label
                >勾选代表你同意
                <router-link to="/copyright" target="_blank" class="copy"
                  ><a @click="close">《版权声明》</a></router-link
                >
                <router-link to="/privacy" target="_blank" class="privacy"
                  ><a @click="close">《隐私声明》</a></router-link
                >
              </label>
            </div>
            <div class="isHas">
              <span>没有账号？</span>
              <router-link to="/register" style="color: #ff9552"
                ><a @click="close">立即注册</a></router-link
              >
            </div>
          </div>
        </div>
        <!-- Warning -->
        <div v-if="warningMsg" class="div-warn">
          <div class="warning">
            <span>{{ msg }}</span>
          </div>
        </div>
      </div>
      <!-- <button @click="close">关闭</button>
      <button @click="submit">登录</button> -->
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { post } from "@/utils/common";
import router from "@/router";
import { init } from "./Login";
import { initLogindata } from "@/utils/common";

export default {
  name: "LoginModal",
  components: {},
  setup(props) {
    const state = reactive({
      visible: false,

      resizetime: null,
      // login: require("../assets/login.jpg"),
      logo: require("../assets/logo3.png"),
      logo2: require("../assets/logo2.png"),
      user: require("../assets/user.png"),
      pwd: require("../assets/pwd.png"),
      tel: require("../assets/tel.png"),
      user2: require("../assets/user2.png"),
      iphone: require("../assets/iphone.png"),
      telError: require("../assets/telErr.png"),
      verity1: require("../assets/verity1.png"),
      verity2: require("../assets/verity2.png"),

      warningMsg: false,
      warning: false,
      telPage: true,
      primary: false,
      telErr: false,
      unRegister: false,
      orErr: false,
      userTel: "",
      validCode: "",
      username: "",
      password: "",
      userPwd: "",
      codeErr: false,
      errMsg: "",
      dragStatus: false,
      msg: "",
      text: "获取验证码",
      disabled: false,
      userErr: false,
      phoneErr:false,
      usernameErr: false,
    });

    onMounted(() => {
      console.log("111");
      drag();
      initdata();
    });
    const initdata = () =>{
      state.warningMsg = false;
      state.warning = false;
      state.telErr = false;
      state.unRegister = false;
      state.orErr = false;
      state.userTel = "";
      state.validCode = "";
      state.username = "";
      state.password = "";
      state.codeErr = false;
      state.errMsg = "";
      state.dragStatus = false;
      state.msg = "";
      state.text = "获取验证码";
      state.disabled = false;
      state.userErr = false;
      state.phoneErr = false;
      state.usernameErr = false;

    }
    // 判断手机号格式
    const onBlur = () => {
      var phone = document.getElementById("iPhone");
      var str = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
       if (state.userTel.length){
        state.phoneErr=false;
        state.codeErr=false;
        }
      if (state.userTel.length > 10) {
        if (str.test(phone.value) == false) {
          state.telErr = true;
        } else {
          state.telErr = false;
        }
      }
    };
    // 手机验证码登录
    const login = () => {
      // 手机号和验证码非空判断
      console.log("判断", !!state.validCode.trim());
       if (!state.userTel.length){
        state.phoneErr=true;
        }else{
          //滑块是否通过
        if (!state.dragStatus) {
          state.warningMsg = true;
          state.msg = "请验证滑块";
          setTimeout(function () {
            state.warningMsg = false;
          }, 2000);
          return;
        }
        }
      if (!!state.userTel.trim() && !!state.validCode.trim()) {
       
        //是否勾选协议
        if (!state.warning) {
          state.warningMsg = true;
          state.msg = "请同意并勾选《版权声明》和《隐私声明》";
          setTimeout(function () {
            state.warningMsg = false;
          }, 2000);
          return;
        }
      }
      post(
        "authByVCode",
        { userTel: state.userTel, validCode: state.validCode },
        (_, res) => {
          console.log("dadadada", res.body.token.body);
          state.visible = false;
          state.codeErr = false;
          // initLogindata();
          initLogindata(res.body);
          callback && callback(true);
          // router.push({
          //   path: "/",
          // });
        },
        (err) => {
          //验证码是否正确
          if (err) {
            state.errMsg = err;
            state.codeErr = true;
            return;
          } else {
             state.codeErr = false;
          }
        }
      );
    };

    //账号登录
    const userLogin = () => {
      if (!state.username.length){
        state.usernameErr=true;
         state.userPwd="请填写账号";
        }
        if (!state.password.length && state.username.length){
        state.usernameErr=true;
        state.userPwd="请输入密码"
        }
    
      // 账号和密码非空判断
      if (!!state.username.trim() && !!state.password.trim()) {
        //是否勾选协议
        if (!state.warning) {
          state.warningMsg = true;
          state.msg = "请同意并勾选《版权声明》和《隐私声明》";
          setTimeout(function () {
            state.warningMsg = false;
          }, 2000);
          return;
        }
        post(
          "auth",
          { username: state.username, password: state.password },
          (_, res) => {
            console.log("dadadada", res.body.token.body);
            state.visible = false;
            initLogindata(res.body);
            callback && callback(true);
            // router.push({
            //   path: "/",
            // });
          },
          (err) => {
            //账号或密码是否正确
            if (err) {
              state.orErr = true;
              return;
            } else {
              state.orErr = false;
            }
          }
        );
      }
    };
    // 判断账号是否注册
    const userBlur = () => {
      var phone = document.getElementById("username");
      var str = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (state.username.length){
        state.usernameErr=false;
        }
      if (state.username.length > 10) {
        if (str.test(phone.value) == false) {
          state.userErr = true;
          state.unRegister = false;
          //  state.btnStatus =true;
          //  state.resErr = false;
        } else {
          state.userErr = false;
          // state.btnStatus = false;
          post("checkRegister", { username: state.username }, (_, res) => {
            if (res.body.feedbackMsg == false) {
              state.unRegister = true;
            } else {
              state.unRegister = false;
            }
          });
        }
      }
    };

    const pwdBlur = () => {
      if (state.password.length){
        state.usernameErr=false;
        }
    }

    var handler;
    var drag_bg;
    var text;
    // 滑块
    const drag = () => {
      if (!state.telPage) return;

      var x,
        dragdom = document.getElementById("drag"),
        isMove = false;
      //添加背景，文字，滑块
      // var html = '<div class="drag_bg"></div>'+
      //             '<div class="drag_text" onselectstart="return false;" unselectable="on">请拖动滑块，拖到最右边</div>'+
      //             '<div class="handler handler_bg"></div>';
      // this.append(html);
      handler = dragdom.getElementsByClassName("handler")[0];
      drag_bg = dragdom.getElementsByClassName("drag_bg")[0];
      text = dragdom.getElementsByClassName("drag_text")[0];

      //鼠标按下时候的x轴的位置
      handler.addEventListener("mousedown", function (e) {
        console.log("mousedown");
        if (text.innerHTML === "验证通过") return;
        isMove = true;
        x = e.pageX - parseInt(handler.style.left || 0, 10);
      });

      //鼠标指针在上下文移动时，移动距离大于0小于最大间距，滑块x轴位置等于鼠标移动距离
      document.addEventListener("mousemove", function (e) {
        if (isMove) {
          var maxWidth =
            (dragdom.clientWidth || 0) - (handler.clientWidth || 0); //能滑动的最大间距
          var _x = e.pageX - x;
          if (_x > 0 && _x <= maxWidth) {
            handler.style.left = `${_x}px`;
            drag_bg.style.width = `${_x}px`;
          } else if (_x > maxWidth) {
            //鼠标指针移动距离达到最大时清空事件
            handler.style.left = `${maxWidth}px`;//当鼠标加速时把移动条置满
            drag_bg.style.width = `${maxWidth}px`;
            state.dragStatus = true;
            dragOk();
          }
        }
      });
      document.addEventListener("mouseup", function (e) {
        if (isMove) {
          var maxWidth =
            (dragdom.clientWidth || 0) - (handler.clientWidth || 0); //能滑动的最大间距
          isMove = false;
          var _x = e.pageX - x;
          if (_x < maxWidth) {
            //鼠标松开时，如果没有达到最大距离位置，滑块就返回初始位置
            handler.style.left = `0px`;
            drag_bg.style.width = `0px`;
          } else {
          }
        }
      });

      //清空事件
      function dragOk() {
        isMove = false;
        handler.classList.remove("handler_bg");
        handler.classList.add("handler_ok_bg");
        text.innerHTML = "验证通过";
        dragdom.style.color = `#fff`;
        handler.removeEventListener("mousedown", drag, false);
        document.removeEventListener("mousemove", drag, false);
        document.removeEventListener("mouseup", drag, false);
      }
    };

    //获取验证码
    const getCode = () => {
      post("getVCode", { userTel: state.userTel }, (_, res) => {
        state.disabled = true;
        let num = 60;
        state.text = `${num}s后重新获取`;
        let timer = setInterval(() => {
          num--;
          if (num > -1) {
            state.text = `${num}s后重新获取`;
          } else {
            clearInterval(timer);
            state.disabled = false;
            state.text = "获取验证码";
          }
        }, 1000);
      },err => {
        if (err) {
          state.warningMsg = true;
          state.msg = err;
          setTimeout(function () {
            state.warningMsg = false;
          }, 2000);
          return;
        }
      });
    };

    // 其他登录方式
    const left = () => {
      state.telPage = true;
      state.primary = false;
      setTimeout(() => drag(), 10);
    };
    const right = () => {
      state.primary = true;
      state.telPage = false;
    };

    let callback;
    function show(_callback) {
      callback = _callback;
      state.visible = true;
      if (handler){
        handler.style.left = `0px`;
        drag_bg.style.width = `0px`;
        text.innerHTML = "请拖动滑块，拖到最右边";
        handler.classList.add("handler_bg");
        handler.classList.remove("handler_ok_bg");
      }
    }

    init(show);
    // 登录逻辑
    // function submit() {
    //   state.visible = false;
    //   initLogindata();
    //   callback && callback(true);
    // }

    function close() {
      //清空数据
      initdata();

      state.visible = false;
      callback && callback();

    }

    const highLight= (e) => {
      // console.log("highLight ==", e);
      // e.target.parentNode.style.border = `1px solid rgb(255, 149, 82,0.5)`;
      // e.target.parentNode.style.boxShadow = `#ff9552 0px 0px 4px 1px`

    }

    const normal = (e) => {
      // e.target.parentNode.style.border = `2px solid #eee`;
      // e.target.parentNode.style.boxShadow = `none`
    }

    const btnstyle= (e) => {
      console.log("btnstyle ==", e);
      e.target.style.boxShadow = `0 0 3px 2px rgba(255, 149, 82, 0.3)`;
      

    }

    const delBtnStyle = (e) => {
      console.log("delBtnStyle ==", e);
      e.target.style.boxShadow = `none`;
    }

    return {
      ...toRefs(state),
      // submit,
      close,
      drag,
      left,
      right,
      onBlur,
      getCode,
      userLogin,
      login,
      userBlur,
      pwdBlur,
      highLight,
      normal,
      btnstyle,
      delBtnStyle,
    };
  },
};
</script>


<style lang="less" scoped>
//空白背景
// .noroot {
//   height: 960px;
//   position: relative;
// }
// //橘色背景
// .page {
//   height: 660px;
//   // top: 140px;
//   position: relative;
//   background-image: url("../assets/login.jpg");
// }
.title {
  // position: absolute;
  color: #fff;
  font-weight: bolder;
  display: flex;
  flex-direction: row;
}
.title-logo {
  width: 40px;
  height: 40px;
  // margin-top: 10px ;
}

.modal {
  width: 500px;
  height: auto;
  // position: absolute;
  background: #fff;
  right: 0px;
  top: 80px;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-title {
  margin: 5%;
  text-align: center;
  font-size: 20px;
}

.input-info,
button,
.tel-inputTel,
#drag,
.verity {
  border-radius: 4px;
  margin-left: 110px;
  font-size: 14px;
}

.input-info {
  border: 2px solid #eee;
  width: 280px;
  height: 36px;
  margin-bottom: 30px;
}

.input-info > input {
  // border: 2px solid #ddd;
  margin-left: 2%;
  height: 36px;
  width: 240px;
}

button {
  width: 284px;
  height: 36px;
  color: #fff;
  background: #ff9552;
}

.user {
  width: 18px;
  height: 18px;
  margin: 5px;
  opacity: 0.7;
}

.pwd {
  width: 30px;
  height: 30px;
}

.forget-pwd {
  color: grey;
  font-size: 14px;
  display: block;
  margin: 15px 0px 15px 320px;
}

.hr {
  color: grey;
  border: 0;
  font-size: 14px;
  padding: 10px 0;
  position: relative;
  margin-top: 15px;
}

.hr::before {
  content: "其他登录方式";
  position: absolute;
  padding: 0 10px;
  line-height: 2px;
  border: solid #eee;
  border-width: 0 170px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
}

.option {
  display: flex;
  flex-direction: row;
  margin: 30px 146px;
  font-size: 14px;
}

.tel,
.user2 {
  width: 30px;
  height: 30px;
  margin-right: 9px;
}

.mid {
  border-right: 2px solid #eee;
  margin: 0 32px;
}
.left {
  margin-right: 3px;
  :hover {
    color: #ff9552;
  }
}

.right:hover {
  color: #ff9552;
}

.footer {
  background: #eee;
  height: 25px;
  font-size: 14px;
  color: grey;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
}

.div-checked {
  // font-size: 12px;
  margin-right: 20px;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  margin-left: 20px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 18px;
  position: relative;
}

input[type="checkbox"]:checked::before {
  content: "\2714";
  background: #ff9552;
  color: #fff;
  width: 20px;
  height: 20px;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 4px;
}

.isHas {
  padding-left: 25px;
}
router-link:hover {
  color: #ff9552;
}

// 手机号登录
.iphone {
  width: 25px;
}
.tel-inputTel {
  display: flex;
  font-display: row;
}
.tel-tip,
.tel-tel,
.verity-num {
  border: 2px solid #eee;
  // width: 300px;
  height: 36px;
  margin-bottom: 25px;
  > input {
    margin-left: 5px;
    height: 36px;
  }
}
.tel-tip {
  width: 60px;
  margin-right: 10px;
  > input {
    margin: 0;
    width: 60px;
  }
}
.tel-tel {
  width: 206px;
  > input {
    margin: 0;
    width: 180px;
  }
}
//滑块
#drag {
  position: relative;
  background: #e8e8e8;
  width: 284px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 25px;
}
#drag .handler {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 37px;
  border: 1px solid #ccc;
  cursor: move;
}

.handler_bg {
  background: #fff url("../assets/dragR.png") no-repeat;
  background-size: 25px;
  background-position: 50% 50%;
}

.handler_ok_bg {
  background: #fff url("../assets/drag1.png") no-repeat;
  background-size: 30px;
  background-position: 50% 50%;
}

#drag .drag_bg {
  background: #7ac23c;
  height: 38px;
  width: 0px;
}

#drag .drag_text {
  position: absolute;
  top: 0px;
  width: 284px;
  user-select: none;
}
//验证码
.verity {
  display: flex;
  margin-bottom: 25px;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
}
.verity-btn {
  width: 100px;
  margin-left: 10px;
  height: 36px;
  background: #ff9552;
  cursor: pointer;
}
.verity-num {
  margin-bottom: 0px;
  width: 170px;
  > input {
    width: 160px;
    height: 36px;
  }
}
// .verity-img {
//   // border: #ccc 1px solid;
//   width: 34px;
//   height: 34px;
//   margin-left: 8px;
//   // background-image: url("../assets/verity1.png");
//   background-size: 30px;
//   background-position: 50% 50%;
// }
//warnijng
.div-warn {
  position: absolute;
  width: 280px;
  height: 50px;
  top: 42%;
  left: 48.3%;
  color: #fff;
  font-size: 14px;
  background: #000;
  opacity: 0.7;
  z-index: 100;
  border-radius: 4px;
  text-align: center;
  padding: 4vh 1vw;
}
//telErr
.tel-err {
  border: 2px solid rgb(247, 118, 118);
  width: 280px;
  height: 34px;
  margin: 0 0 15px 110px;
  border-radius: 4px;
  font-size: 14px;
  background: rgb(255, 218, 218);
  line-height: 2rem;
  // opacity: 0.7;
}
.telErr {
  width: 20px;
  margin: 0 5px;
}
//codeErr
.codeErr {
  font-size: 14px;
  color: red;
  width: 70px;
  height: 16px;
  position: absolute;
  top: 57%;
  left: 24%;
}
.copy:hover {
  color: #ff9552;
}
.privacy:hover {
  color: #ff9552;
}
</style>