<template>
  <div class="know_header p_relative">
    <img
      class="swiper-img w100"
      :src="require('../pages/app/images/knowbanner2.jpg')"
    />
    <div class="search">
      <div class="search_main">
        <div class="input_d">
          <input placeholder="请输入搜索关键词" @keydown="enter" />
        </div>
        <div class="search_keys">
          <div>热门搜索：</div>
          <div
            class="search_keey_item pointer ellipsis"
            v-for="(i, x) in searchKeys"
            :key="x"
            @click="search((i || '').trim())"
          >
            {{ i }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import router from "@/router";
import { load, save } from "@/utils/storage";
import { get, post, getLogindata } from "@/utils/common";
import { login } from "@/components/Login";
export default {
  name: "Search",
  props: {
    enterCallBack: {
      default: () => {},
    },
  },
  emits: ['loginStatus'],
  setup(props, context) {
    const state = reactive({
      searchKeys: [],
    });

    onMounted(() => {
      getHotSearch();
    });

    const getHotSearch = () => {
      get("knowledgeBase/queryHotSpot", (data, res) => {
        console.log("data ===", data, res);
        if (data) {
          state.searchKeys = data.hotSpot || [];
        }
      });
    };

    const enter = (e) => {
      console.log("event", e);
      if (e.keyCode == 13) {
        const val = (e.target.value || "").trim();
        search(val);
      }
    };

    const search = (val) => {
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
        login((logon) => {
          if (logon) {
            console.log(props);
            context.emit('loginStatus');
            // props.loginStatus &&  props.loginStatus(false);
            save("searchVal", val);
            if (!val) return;
            const list = load("searchHisTory") || [];
            const newList = [val, ...list];
            save("searchHisTory", newList);
            router.push({
              path: "/know_search",
            });

            setTimeout(() => {
              props.enterCallBack && props.enterCallBack();
            }, 100);
          }
        });
        return;
      }
      save("searchVal", val);
      if (!val) return;
      const list = load("searchHisTory") || [];
      const newList = [val, ...list];
      save("searchHisTory", newList);
      router.push({
        path: "/know_search",
      });

      setTimeout(() => {
        props.enterCallBack && props.enterCallBack();
      }, 100);
    };

    return {
      ...toRefs(state),
      enter,
      search,
    };
  },
};
</script>

<style lang="less" scoped>
.know_header {
  object-fit: cover;
}

.know_tab {
  // position: sticky;
  // top: 0;
  // left: 0;
  display: flex;
  justify-content: center;
  font-size: 1.04vw;
  font-family: SimHei;
  font-weight: bold;
  color: #666;
  z-index: 999;
  background: #fff;
  border-bottom: 1px solid #e7e5e4;

  & > div {
    cursor: pointer;
    padding: 0.36vw 1.04vw;
    border-radius: 20px;
    background-color: rgba(255, 149, 82, 0);
    margin: 1.04vw 2.08vw 1.04vw 0px;
    transition: all 0.3s;
  }

  .tab_active {
    color: #fff;
    background-color: rgba(255, 149, 82, 1) !important;
  }
}

.search {
  width: 100%;
  position: absolute;
  bottom: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search_main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input_d {
      width: 40vw;
      height: 45px;
      border-radius: 20px;
      margin-bottom: 15px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        padding-left: 30px;
        font-size: 15px;
      }

      input::-webkit-input-placeholder {
        color: #999;
        // font-weight: bold;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999;
        // font-weight: bold;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999;
        // font-weight: bold;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
        // font-weight: bold;
      }
    }

    .search_keys {
      bottom: -20px;
      display: flex;
      font-size: 14px;
      font-family: SimHei;
      font-weight: bold;
      color: #666666;
      padding-left: 30px;

      .search_keey_item {
        max-width: 8em;
        margin-right: 20px;
        transition: color 0.1s;

        &:hover {
          color: #ff9552;
        }
      }
    }
  }
}
</style>