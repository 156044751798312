import { createApp, reactive } from 'vue'
import App from './App.vue'
import router from './router'
import './reset.css';
import './base.less';
import './common.less';
import './icon.less';
import './assets/iconfont/iconfont.css';
import Pagination from './components/Pagination.vue';
import KnowledgeGraph from './components/KnowledgeForceGraph.vue';
import KnowGraph from './components/KnowGraph.vue';
import Search from './components/Search.vue';
import Avatar from './components/Avatar.vue';
import './ckeditor-content.css';
import './ckeditor-content-diy.css';
import { post } from './utils/common';

const app = createApp(App);

app.component('Pagination', Pagination);
app.component('KnowledgeGraph', KnowledgeGraph);
app.component('KnowGraph', KnowGraph);
app.component('Search', Search);
app.component('Avatar', Avatar);

export const $msg = app.config.globalProperties.$msg = reactive({
    loading: ''
})

const collection = app.config.globalProperties.$collection = reactive({
    articleCollections: [],
    articleCollectionNum: null,
    courseCollectionNum: null,
    courseCollections: []
})

export function updateCollect() {
    post('userInformation/myCollections', {}, res => {
        collection.articleCollections = res.articleCollections;
        collection.articleCollectionNum = res.articleCollectionNum;
        collection.courseCollectionNum = res.courseCollectionNum;
        collection.courseCollections = res.courseCollections.map((item: any) => {
            item.imgUrl = item.imgUrl ? JSON.parse(item.imgUrl)[0] : "";
            return item;
        });;
    })
}

app.config.globalProperties.links = reactive([
    {
        name: "首页",
        url: "/",
    },
    {
        name: "平台介绍",
        url: "/intro",
    },
    {
        name: "平台动态",
        url: "/trend",
    },
    {
        name: "课程资源",
        url: "/courses",
    },
    {
        name: '知识库',
        // url: '/know_search'
        url: '/knowledge'
    }
]);


app.use(router).mount('#app');

