import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/intro',
    name: 'Intro',
    component: () => import('@/views/Intro.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('@/views/Download.vue'),
  },
  {
    path: '/trend',
    name: 'Trend',
    component: () => import('@/views/Trend.vue'),
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: () => import('@/views/Article.vue'),
  },
  {
    path: '/zarticle/:zone/:id',
    name: 'ZoneArticle',
    component: () => import('@/views/Article.vue'),
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('@/views/Courses.vue'),
  },
  {
    path: '/course/:id',
    name: 'Course',
    component: () => import('@/views/Course.vue'),
  },
  {
    path: '/zcourse/:zone/:id',
    name: 'ZoneCourse',
    component: () => import('@/views/Course.vue'),
  },
  {
    path: '/knowledge',
    name: 'Knowledge',
    component: () => import('@/views/Knowledge.vue'),
  },
  { // 知识库搜索页
    path: '/know_search',
    name: 'KnowSearch',
    component: () => import('@/views/KnowSearch.vue'),
  },
  { // 知识库详情
    path: '/know_detail',
    name: 'KnowDetail',
    component: () => import('@/views/KnowDetail.vue'),
  },
  { // 我的
    path: '/my',
    name: 'My',
    component: () => import('@/views/My.vue'),
  },
  { // 测试页
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Test.vue'),
  },
  { // 登录页
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },
  { //注册页
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue'),
  },
  { // 版权声明
    path: '/copyright',
    name: 'Copyright',
    component: () => import('@/views/Copyright.vue'),
  },
  { // 隐私声明
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
  },
  { //找回密码
    path: '/retrievePwd',
    name: 'RetrievePwd',
    component: () => import('@/views/RetrievePwd.vue'),
  }
  ,
  {
    // 修改手机号
    path: '/changePhone',
    name: 'ChangePhone',
    component: () => import('@/views/ChangePhone.vue'),
  },
  {
    // 我的专区
    path: '/zone',
    name: 'Zone',
    component: () => import('@/views/Zone.vue'),
  },
  {
    path: '/:arg(.*)+',
    redirect: '/'
  }
]

import { $msg } from '../main';

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    $msg.loading = '';
  }
})

export default router
