<template>
    <div class=" p_relative d-flex avatardiv" style="height:50px;line-height:50px">
        
        <!-- <img style="width:32px;height:32px;margin-right:20px;margin-top:10px;cursor: pointer;" :src="notify" alt="" /> -->
        <!-- 登陆前 -->
        <div class="logRes" v-if="noLogin">
            <div @mouseenter="boxModal"><router-link to="/login"><span  class="logTip">登录&nbsp;&nbsp;&nbsp;&nbsp;</span></router-link>|<router-link to="/register"><span class="logTip">&nbsp;&nbsp;&nbsp;&nbsp;注册</span></router-link></div>
            <div v-if="box" @mouseleave="closeBox" class="box">
              <div class="box-dt"><span style="color:#FF9552">登录/注册</span>后，可享受：</div>
              <hr style="margin: 10px 0">
              <div class="box-dd"><img src="../assets/lesson.png">观看名师课程</div>
              <div class="box-dd"><img src="../assets/knowledge.png">知识库中畅游</div>
              <div class="box-dd"><img src="../assets/collect.png">收藏海量知识</div>
              <router-link to="/login"><button>登录</button></router-link>
            </div>
          </div>

        <!-- 登录后 -->
        <div class="logAvatar"  v-else>
          <img class="avatar" style="cursor: pointer;" @mouseenter="avatarModal" :src="loginData.headImg" alt="">
            
                <div
                class="userModal"
                v-if="avatarModalStatus"
                @mouseleave="avatarModalLeave"
                >
                <div style="border-bottom: 1px solid #E0E0E0; height: 2rem;line-height:2rem;padding-bottom: 0.5rem;">
                    {{ loginData.userName }}
                </div>
                <div
                    class="group"
                    style="
                    display: flex;
                    flex: 1;
                    height: 90%;
                    flex-direction: column;
                    justify-content: space-around;
                    "
                >
                    <div  @click="myPage('1')">我的专区</div>
                    <div  @click="myPage('2')">我的收藏</div>
                    <div  @click="myPage('3')">资料与账号</div>
                    <div style="height:1px;width:100%; background: #E0E0E0;"></div>
                    <div @click="myPage('0')">退出</div>
                </div>
                </div>
        </div>
         <div v-show="msgState" style="height:143px;line-height:143px;font-size:19px" class='msgBox'><span style="width:100%">{{msg}}</span></div>
   
    </div>
</template>


<script >
import { reactive, toRefs, onMounted, watch, nextTick,  } from "vue";
import { post, getLogindata } from "@/utils/common";
import { save } from "@/utils/storage"
import { useRoute, useRouter } from "vue-router";
export default {
  name: "Avator",
  props:{
    imgUrl:String,
    nickname:String,
    updataTime:Number,
    changeDeatail:{
      default: () => {},
    },
  },
  setup(props) {
    
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      updataTime:null,
      noLogin: true,
      box: false,
      notify: require("../assets/notify.png"),
      loginData: {},
      avatarModalStatus:false,
      activekey:'',
      msgState:false,
      msg:'',
    });

    watch(() => props.updataTime, (newUrl) => {
      console.log('参数',props);
      if(state.updataTime != props.updataTime){
        console.log('刷新了');
      }
      const logindatas = getLogindata();
      logindatas.headImg = props.imgUrl;
      logindatas.userName = props.nickname;
      state.loginData = logindatas;
      save('logindata', logindatas);
    })

    onMounted(() => {
      
      let isLogin = getLogindata() || {};
      if(!isLogin.headImg){
        
        state.noLogin = true;
        return;
      }else{
        state.noLogin = false;
        state.loginData = getLogindata();
      }
    });

    const LoginStatus = (status) =>{
      state.noLogin = status;
      state.loginData = getLogindata();
      state.avatarModalStatus = false;
    }

    const boxModal = () => {
      state.box=true;
    }
    const closeBox = () => {
      state.box=false;
    }
    const avatarModal = () => {
      state.avatarModalStatus = true;
    };

    const avatarModalLeave = () => {
      state.avatarModalStatus = false;
    };
    const myPage = (index) => {
      if (index == "1") {
        post(`hospitalZoneWeb/getIndex`,{},res=>{
          document.documentElement.scrollTop = 0;
          router.push({
            path:'/zone'
          })
          props.changeDeatail && props.changeDeatail();
          return;
        },err=>{
          state.msgState = true;
          state.msg = err;
          setTimeout(() => {
            state.msgState =false;
          }, 3000);
          return;
        })
      }else{

        state.activekey = index;
        if (index == "0") {
          // 跳到登录页并且清除缓存
      
          save('logindata', null)
          state.noLogin = true;
          return;
        }
        router.push({
          path: "/my",
          query: {
            myIndex: index,
          },
        });
      }
    };

    return {
      ...toRefs(state),
      //   itemClick
      avatarModal,
      avatarModalLeave,
      myPage,
      boxModal,
      closeBox,
      LoginStatus
    };
  },
};
</script>

<style scoped lang="less">
.avatar {
    width: 50px;
    height:50px;
    border-radius: 50%;
    
    margin: 0;
}

.userModal {
  position: absolute;
  z-index: 999;
  top: 54px;
  right: -13px;
  background: #fff;
  width: 10rem;
  height: 15rem;
  padding: 1rem;
  box-shadow: 2px 2px 3px #888888;
}

.group :hover{
  color:#f39801;
  cursor: pointer;
}

.avatardiv{
    justify-content: center;
}

.msgBox{
  position: absolute;
  top: 332px;
  background: rgba(76, 76, 76, .9);
  border-radius: 10px;
  color: #fff;
  width: 317px;
  z-index: 999;
  text-align: center;
  right: 241px;
}

//未登录
.logRes {
    position: relative;
    width: 130px;
    height: 22px;
    button{
        height: 1.8rem;
        width: 10rem;
        background: #FF9552;
        color: #fff;
        margin-left: .5rem;
        border-radius: 4px;
        cursor: pointer;
    }
}
.logTip:hover {
color: #FF9552 ;
cursor: pointer;
}
//已登录
.logAvatar{
    height: 100%;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logRes .box {
    top:185%;
    left:-50%;
  width:11rem;
  height:13.5rem;
  background:#fff;
  position:absolute;
  padding: 10px;
  z-index: 9999;
  border-radius: 4px;
}
.box-dd{
    height: 2rem;
    > img{
    width: 20px;
    margin: 0 15px;
    }
}

</style>

