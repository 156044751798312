
const app_pre = "phw_";

const cache: {
    [key: string]: any
} = {};


export const save = (key: string, value: any) => {
    key = app_pre + key;
    cache[key] = value;
    if (window.localStorage) {
        if (value === undefined || value === null) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    }
}

export const load = (key: string) => {
    key = app_pre + key;
    if (!(key in cache)) {
        if (window.localStorage) {
            const data = window.localStorage.getItem(key);
            // console.log('localstore >', data);
            if (data === null || data === undefined) {
                cache[key] = undefined;
            } else {
                cache[key] = JSON.parse(data);
            }
        } else {
            cache[key] = undefined;
        }
    }
    return cache[key];
}

const session_cache: {
    [key: string]: any
} = {};

// sessionStorage
export const sessionSave = (key: string, value: any) => {
    key = app_pre + key;
    session_cache[key] = value;
    if (window.sessionStorage) {
        if (value === undefined || value === null) {
            window.sessionStorage.removeItem(key);
        } else {
            window.sessionStorage.setItem(key, JSON.stringify(value));
        }
    }
}

export const sessionLoad = (key: string) => {
    key = app_pre + key;
    if (!(key in session_cache)) {
        if (window.sessionStorage) {
            const data = window.sessionStorage.getItem(key);
            if (data === null || data === undefined) {
                session_cache[key] = undefined;
            } else {
                session_cache[key] = JSON.parse(data);
            }
        } else {
            session_cache[key] = undefined;
        }
    }
    return session_cache[key];
}
