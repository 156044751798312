import request, { head } from 'superagent';
import router from '../router/index';
import { save, load, sessionLoad, sessionSave } from '../utils/storage';

const header: any = {

}

const logindata_key = "logindata";

// const host = `https://ysfwzx.365yyf.com`;
// const host = `https://uat.tangusoft.com`;

const host = '';

const base = `${host}/phsapp/`;

const schema = process.env.NODE_ENV === 'development' ? '' : 'https:';

export function fixurl(url: string) {
    if (/^\/\//.test(url)) {
        return schema + url;
    } else if (/^\/fileserver/.test(url)) {
        return host + url;
    }
    return url;
}

let logindata: any ;

export const initLogindata: Function = (_logindata: any = {}) => {
    if (_logindata.token) {
        logindata = _logindata
        header.Authorization = 'Bearer ' + _logindata.token.body;
    } else {
        logindata = null;
        delete header.Authorization;
    }
    storageSave(logindata_key, _logindata);
}

export function getLogindata(){
    return load(logindata_key);
}

storageLoad(logindata_key, (data: any)  => {
    initLogindata(data);
})

export function storageSave(key: string, value: any, saveType = 'localStorage') {
    if (saveType === 'localStorage') {
        save(key, value);
    } else {
        sessionSave(key, value);
    }
}

export function storageLoad(key: string, callback: Function, saveType = 'localStorage') {
    if (saveType === 'localStorage') {
        callback && callback(load(key))
    } else {
        callback && callback(sessionLoad(key))
    }
}

export const block = (fn: Function, block_time = 500, context: any) => {
    let time = 0;
    return function () {
        const now = Date.now();
        if (time < now) {
            time = now + block_time;
            fn && fn.apply(context, arguments);
            return;
        }
    }
}

export const delay = (fn: Function, delay_time = 300, context: any) => {
    let handler: any;
    return function () {
        if (handler) {
            clearTimeout(handler);
        }
        handler = setTimeout(function () {
            fn && fn.apply(context, arguments);
            handler = 0;
        }, delay_time);
    }
}

export function get(url: string, success?: (data: any, res: request.Response) => void, fail?: (err: string, res: request.Response) => void) {
    return ajax({
        url,
        type: 'get',
        success,
        fail
    })
}

export function post(url: string, data: any, success?: (data: any, res: request.Response) => void, fail?: (err: string, res: request.Response) => void) {
    return ajax({
        url,
        type: 'post',
        data,
        success,
        fail
    })
}

type AjaxType = 'get' | 'post';

export function ajax({ url, type = 'get', data, success, fail, c_base, silence = false }: {
    url: string,
    data?: any,
    type?: AjaxType,
    success?: (data: any, res: request.Response) => void,
    fail?: (err: string, res: request.Response) => void,
    c_base?: string,
    silence?: boolean
}) {
    return new Promise((resolve, reject) => {
        if (!/(^https?:\/\/)|(^\.?\/)/i.test(url)) {
            url = (c_base || base) + url;
        }
        const cb = (err: any, res: request.Response) => {

            // if (res && res.status === 401) {
            //     initLogindata({});
            //     router.push({
            //         name: 'Login',
            //     });
            //     return;
            // }
            if (err) {
                const error = '服务器异常：' + err;
                fail && fail(error, res);
                reject(error)
                return;
            }
            if (res.body && res.body.errorMsg) {
                const error = res.body.errorMsg;
                fail && fail(error, res);
                reject(error)
                return;
            }
            success && success(res.body, res);
            resolve(res.body);
        }
        if (type === 'get') {
            if (data !== null && data !== undefined) {
                url += `&data=${encodeURIComponent(JSON.stringify(data))}`;
            }
            request.get(url).set(header).end(cb);
        } else {
            request.post(url).set('Content-Type', 'application/json').set(header).send(data).end(cb);
        }
    })

}


const regex_spicial: {
    [key: string]: string
} = {};

['.', '$', '^', '{', '[', '(', '|', ')', '*', '+', '?', '\\'].forEach(item => {
    regex_spicial[item] = '\\' + item;
});

export const buildTextRegExp = (text: string, modifiers: string) => {
    const s = [];
    if (text) {
        for (var i = 0, c; c = text.charAt(i); i += 1) {
            s.push(regex_spicial[c] || c);
        }
    }
    return new RegExp(s.join(''), modifiers);
}

export const buildTextWholeRegExp = (text: string, modifiers: string) => {
    const s = [];
    if (text) {
        for (var i = 0, c; c = text.charAt(i); i += 1) {
            s.push(regex_spicial[c] || c);
        }
    }
    return new RegExp('^' + s.join('') + '$', modifiers);
}